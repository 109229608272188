<template>
    <div class="kmCenter">
      <div class="handBox">
        <el-button type="primary" @click="goBack" style="width: 92px;"
          >返回</el-button
        >
        <span class="pageTopic">十周年用户中心</span>
      </div>
      <div class="searchBar">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-width="80px"
        >
          <el-form-item label="状态" label-width="60px">
            <el-select
              v-model="formInline.status"
              placeholder="请选择"
              clearable
              @clear="search"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" label-width="80px">
            <el-input
              v-model="formInline.name"
              placeholder="请输入"
              @clear="search"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="formInline.phone"
              placeholder="请输入"
              @clear="search"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="主套系">
            <el-select
              v-model="formInline.mainPlanDone"
              placeholder="请选择"
              clearable
              @clear="search"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附加套系">
            <el-select
              v-model="formInline.subPlanDone"
              placeholder="请选择"
              clearable
              @clear="search"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="关键字">
            <el-input
              v-model="formInline.keyWord"
              @input="search"
              placeholder="姓名/手机号/备注/套系金额 模糊搜索"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="search"
              :loading="tableLoading"
              style="width: 92px; margin-left: 20px"
              >查询</el-button
            >
            <el-button
              type="success"
              @click="openAdd"
              style="width: 92px; margin-left: 20px"
              >导入</el-button
            >
            <el-button
              type="warning"
              @click="exportExcel"
              style="width: 102px; margin-left: 20px"
              >导出Excel</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="mainList">
        <el-table
          :data="tableData"
          style="width: 100%; margin-top: 10px"
          stripe
          border
          size="medium"
        >
          <el-table-column align="center" label="序号" width="60" fixed="left">
            <template slot-scope="scope">
              <div>{{ scope.$index + (pages.page - 1) * pages.pageNum + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="date"
            label="录入/修改日期"
            width="130"
            fixed="left"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="宝宝姓名"
            fixed="left"
            width="120"
          ></el-table-column>
          <el-table-column align="center" prop="orderPrice" label="套系金额" width="140"></el-table-column>
          <el-table-column align="center" prop="status" label="套系情况" width="180">
            <template slot-scope="scope">
              <div>
                <div
                  :class="[
                    { 'redFont': scope.row.mainPlanDone == 0 },
                    { 'blueFont': scope.row.mainPlanDone == '' },
                    { 'greenFont': scope.row.mainPlanDone == 1 },
                  ]"
                >
                  主套系
                  <i class="el-icon-success" v-if="scope.row.mainPlanDone == 1"></i>
                  {{
                    scope.row.mainPlanDone == 0 
                      ? "未完成"
                      : scope.row.mainPlanDone == ''|| scope.row.mainPlanDone === undefined
                      ? "-"
                      : scope.row.mainPlanDone == 1
                      ? "已完成"
                      : ""
                  }}
                </div>
                <div
                  :class="[
                    { 'redFont': scope.row.subPlanDone == 0 },
                    { 'blueFont': scope.row.subPlanDone == '' },
                    { 'greenFont': scope.row.subPlanDone == 1 },
                  ]"
                >
                  附加套系
                  <i class="el-icon-success" v-if="scope.row.subPlanDone == 1"></i>
                  {{
                    scope.row.subPlanDone == 0 
                      ? "未完成"
                      : scope.row.subPlanDone == ''|| scope.row.subPlanDone === undefined
                      ? "-"
                      : scope.row.subPlanDone == 1
                      ? "已完成"
                      : ""
                  }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="birthday"
            label="生日"
            width="180"
          ></el-table-column>
  
          <el-table-column
            align="center"
            prop="birthday"
            label="年龄"
            width="180"
          >
            <template slot-scope="scope" >
              <span v-if="scope.row.birthday">
                {{  getAge(scope.row.birthday)  }}
              </span>
              <span v-else> - </span>
            </template>
          </el-table-column>
  
          <el-table-column
            align="center"
            prop="faName"
            label="宝爸"
            width="120"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="momName"
            label="宝妈"
            width="120"
          ></el-table-column>
          <el-table-column align="center" prop="phone" label="手机号" width="180"></el-table-column>
          <el-table-column align="center" prop="orderDate" label="订单日" width="140"></el-table-column>
          <el-table-column align="center" prop="photoDate" label="拍摄日" width="140"></el-table-column>
          <el-table-column
            align="center"
            prop="principal"
            label="门市"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="isRecharge"
            label="是否充值"
            width="100"
          >
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.isRecharge?'是': '否'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="gift2"
            label="充值金额"
            width="100"
          >
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.rechargeType
                }}
              </div>
            </template>
          </el-table-column>
  
          <el-table-column align="center" prop="status" label="状态" width="130">
            <template slot-scope="scope">
              <div
                :class="[
                  { redFont: scope.row.status == 1 },
                  { blueFont: scope.row.status == 2 },
                  { greenFont: scope.row.status == 3 },
                ]"
              >
                <i class="el-icon-info" v-if="scope.row.status == 1"></i>
                <i class="el-icon-s-flag" v-if="scope.row.status == 2"></i>
                <i class="el-icon-success" v-if="scope.row.status == 3"></i>
                {{
                  scope.row.status == 1
                    ? "未处理"
                    : scope.row.status == 2
                    ? "处理中"
                    : scope.row.status == 3
                    ? "已完成"
                    : ""
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="comment" label="备注">
          </el-table-column>
          <el-table-column align="center" label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <div @click="editConsumer(scope)">
                <el-button size="small" type="primary">编辑</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total,sizes, prev, pager, next"
          :current-page="pages.page * 1"
          :total="pages.total"
          :page-size="pages.pageNum * 1"
          style="margin-top: 20px"
          :page-sizes="[10, 12, 15, 17, 20, 25, 30, 100, 1000, pages.total]"
          @size-change="changePageSize"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
      <!-- 弹窗 1  -->
      <el-dialog
        :title="isEdit ? '修改信息' : '客户导入'"
        :visible.sync="dialogVisible"
        width="900px"
      >
        <el-form
          ref="form"
          :model="form"
          label-width="70px"
          label-position="left"
        >
          <div style="display:flex;" >
            <div style="flex:1;margin-right:50px;">
              <el-form-item label="宝宝">
                <el-input v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="生日">
              <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="是否充值" >
                <el-switch  v-model="form.isRecharge"  active-color="#13ce66"  inactive-color="#95a5a6"></el-switch>
              </el-form-item>
              <el-form-item label="充值金额" v-if="form.isRecharge">
                <el-input v-model="form.type" placeholder="请输入"></el-input>
                <!-- <el-select
                  v-model="form.type"
                  placeholder="请选择"
                  style="width: 100%"
                  @change="giftTypeChange"
                  clearable
                >
                  <el-option label="充值1500" value="1"></el-option>
                  <el-option label="充值2000" value="2"></el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="主套系" >
                <el-switch  v-model="form.mainPlanDone" inactive-text="未完成" active-text="已完成" 
                inactive-value="0" active-value="1"
                active-color="#13ce66"  inactive-color="#95a5a6"></el-switch>
              </el-form-item>
              <el-form-item label="附加套系" >
                <el-switch  v-model="form.subPlanDone" inactive-text="未完成"  active-text="已完成"
                  inactive-value="0" active-value="1"
                 active-color="#13ce66"  inactive-color="#95a5a6"></el-switch>
              </el-form-item>

              <el-form-item label="状态">
                <el-select
                  v-model="form.status"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                >
                  <el-option label="未处理" value="1"></el-option>
                  <el-option label="处理中" value="2"></el-option>
                  <el-option label="已完成" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="门市">
                <el-input v-model="form.principal" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input
                  v-model="form.comment"
                  type="textarea"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
  
            <div style="flex:1" >
              <el-form-item label="宝爸">
                <el-input v-model="form.faName" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="宝妈">
                <el-input v-model="form.momName" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="手机号">
                <el-input v-model="form.phone" placeholder="请输入 宝爸 / 宝妈 手机号"></el-input>
              </el-form-item>
              <el-form-item label="套系金额">
                <el-input v-model="form.orderPrice" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="订单日">
                <el-date-picker
                v-model="form.orderDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="拍摄日">
                <el-date-picker
                v-model="form.photoDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                >
              </el-date-picker>
            </el-form-item>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            v-if="!isEdit"
            type="primary"
            @click="onSubmit"
            :loading="btnLoading"
            >确定新增</el-button
          >
          <el-button :loading="btnLoading" v-else type="primary" @click="onEdit"
            >确定修改</el-button
          >
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "NiceConsumerList",
    components: {},
    data() {
      return {
        formInline: {
          name: "",
          phone: "",
          date: "",
          keyWord: "",
          status: "",
          faName: "",
          momName: "",
          mainPlanDone: "",
          subPlanDone: "",
        },
        pages: {
          page: 1,
          pageNum: 15,
          total: 0,
        },
        tableData: [],
        dialogVisible: false,
        // 添加导入数据
        form: {
          _id: "",
          name: "",
          phone: "",
          principal: "",
          isRecharge: false,
          faName: "",
          momName: "",
          birthday: "",
          orderDate: "",
          photoDate: "",
          type: "",
          comment: "",
          status: "",
          orderPrice: "", // 套系金额
          mainPlanDone: '0',
          subPlanDone: '0'
        },
        isEdit: false, // 判断是否为编辑
        options2: [
          {
            value: "",
            label: "全部",
          },
          {
            value: '1',
            label: "已完成",
          },
          {
            value: '0',
            label: "未完成",
          },
        ],
        options: [
          {
            value: "1",
            label: "未处理",
          },
          {
            value: "2",
            label: "处理中",
          },
          {
            value: "3",
            label: "已完成",
          },
        ],
        tableLoading: false,
        btnLoading: false,
      };
    },
    created() {},
    mounted() {
      this.formInline.keyWord = this.$route.query.keyword?this.$route.query.keyword:'';
      this.formInline.name = this.$route.query.name?this.$route.query.name:'';
      this.getData();
    },
    methods: {
      getAge(birthday) {
        // birthday  2001-1-1
  
        let nowYear = new Date().getFullYear();
  
        let year = birthday.split('-')[0]
  
        console.log(year)
        
        return nowYear - year
      },
      goBack() {
        this.$router.go(-1);
      },
      search() {
        this.pages.page = 1;
        this.getData();
      },
      changePage(val) {
        this.pages.page = val * 1;
        this.getData();
      },
      giftTypeChange() {
        // this.$set(this.form,'type',val);
        // 不强制更新 会导致数据变化视图没有变化
        this.$forceUpdate();
      },
      // 获取数据
      async getData() {
        this.tableLoading = true;
        await axios
          .get(
            this.$baseUrl +
              "/tenthCon?" +
              "page=" +
              this.pages.page +
              "&pageNum=" +
              this.pages.pageNum +
              "&name=" +
              this.formInline.name +
              "&faName=" +
              this.formInline.faName +
              "&momName=" +
              this.formInline.momName +
              "&phone=" +
              this.formInline.phone +
              "&status=" +
              this.formInline.status +
              "&mainPlanDone=" +
              this.formInline.mainPlanDone +
              "&subPlanDone=" +
              this.formInline.subPlanDone +
              "&date=" +
              this.formInline.date +
              "&keyWord=" +
              this.formInline.keyWord
          )
          .then((res) => {
            setTimeout(() => {
              this.tableLoading = false;
            }, 200);
            this.tableData = res.data.list;
            this.pages = res.data.pages;
          })
          .catch((err) => {
            this.tableLoading = false;
            console.log(err);
          });
      },
      //导出excel
      exportExcel() {
        axios
          .get(
            this.$baseUrl +
              "/tenthCon/exportExcel?" +
              "page=" +
              this.pages.page +
              "&pageNum=" +
              this.pages.pageNum +
              "&name=" +
              this.formInline.name +
              "&faName=" +
              this.formInline.faName +
              "&momName=" +
              this.formInline.momName +
              "&phone=" +
              this.formInline.phone +
              "&status=" +
              this.formInline.status +
              "&date=" +
              this.formInline.date +
              "&mainPlanDone=" +
              this.formInline.mainPlanDone +
              "&subPlanDone=" +
              this.formInline.subPlanDone +
              "&keyWord=" +
              this.formInline.keyWord
          )
          .then((res) => {
            console.log("导出");
            window.open(res.data.url);
          });
      },
      // 点击导入
      openAdd() {
        this.form = {
          _id: "",
          name: "",
          phone: "",
          principal: "",
          isRecharge: false,
          faName: "",
          momName: "",
          birthday: "",
          orderDate: "",
          photoDate: "",
          type: "",
          comment: "",
          status: "",
          orderPrice: '',
          mainPlanDone: '0',
          subPlanDone: '0'
        };
        this.dialogVisible = true;
        this.isEdit = false;
      },
      // 点击编辑，编辑已有用户信息
      editConsumer(scope) {
        this.isEdit = true;
        this.dialogVisible = true;
        let {
          name,
          phone,
          principal,
          comment,
          status,
          _id,
          isRecharge,
          faName,
          momName,
          birthday,
          orderDate,
          photoDate,
          rechargeType,
          orderPrice,
          mainPlanDone,
          subPlanDone
        } = scope.row;
        this.form = { name, phone, principal, comment, status, _id, isRecharge, type:rechargeType, faName,
          momName,
          birthday,
          orderDate,
          photoDate,
          mainPlanDone: mainPlanDone?mainPlanDone:'0',
          subPlanDone: subPlanDone?subPlanDone:'0',
          orderPrice: orderPrice?orderPrice:''  };
      },
      // 确认添加
      onSubmit() {
        this.btnLoading = true;
        let param = { ...this.form };
        param.rechargeType = this.form.type;
  
        console.log(param, "param");
  
        let date = new Date().getMonth() + 1 + "." + new Date().getDate();
        param.date = date;
        delete param.type;
        delete param._id;
        console.log(param, "新增");
        if (param.name.trim() == "" && param.phone.trim() == "") {
          this.$message({
            showClose: true,
            message: "请填写姓名或手机号",
            type: "warning",
          });
          this.btnLoading = false;
          return;
        }
        axios
          .post(this.$baseUrl + "/tenthCon/addConsumer", param)
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res.data, "添加成功");
              this.$message({
                duration: "2000",
                showClose: true,
                message: "添加成功！",
                type: "success",
              });
              this.dialogVisible = false;
              this.btnLoading = false;
              this.search();
            }
            if (res.data.code == 302) {
              this.$message({
                duration: "2000",
                showClose: true,
                message: "重复手机号请检查输入信息！",
                type: "warning",
              });
              this.btnLoading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.btnLoading = false;
          });
      },
      // 确认编辑
      onEdit() {
        this.btnLoading = true;
        let param = { ...this.form };
        param.rechargeType = this.form.type;
  
        console.log(param, "param");
  
        let date = new Date().getMonth() + 1 + "." + new Date().getDate();
        param.date = date;
        delete param.type;
        console.log(param, "编辑");
        if (param.name.trim() == "" && param.phone.trim() == "") {
          this.$message({
            showClose: true,
            message: "请填写姓名或手机号",
            type: "warning",
          });
          this.btnLoading = false;
          return;
        }
        axios
          .post(this.$baseUrl + "/tenthCon/editInfo", param)
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res.data, "修改数据");
              this.$message({
                duration: "2000",
                showClose: true,
                message: "修改成功！",
                type: "success",
              });
              this.btnLoading = false;
              this.search();
              this.dialogVisible = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.btnLoading = false;
          });
      },
      // 修改每页选择数量
      changePageSize(val) {
        this.pages.pageNum = val;
        this.getData();
      },
    },
  };
  </script>
  <style scoped lang="less">
  .kmCenter {
    height: 100%;
    width: 100%;
    padding: 10px 50px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    .searchBar,
    .manlist {
      margin: 10px auto;
    }
    .searchBar {
      border: 1px solid #284faa;
      border-radius: 4px;
      padding-top: 22px;
    }
    .mainList {
      padding: 10px 0;
    }
    .redFont {
      font-weight: 500;
      color: #492525;
    }
    .blueFont {
      font-weight: 500;
      color: #2e62cd;
    }
    .greenFont {
      font-weight: 500;
      color: #349e69;
    }
  }
  </style>
  